/*-------------------------------------------*
    basic style sidedrawer
*-------------------------------------------*/

.side-drawer {
  height: 100%;
  background: #474f5a;
  position: fixed;
  top: 0;
  left: 0;
  width: 350px;
  z-index: 200;
  transform: translateX(-100%);
  /*transition: transform 0.3s ease-out;*/
}

.navigation {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding-bottom: 1rem;
}

.side-drawer.open {
  transform: translateX(0);
}

.side-drawer.close {
  transform: translateX(-100);
  /*transition: opacity 0.3s ease-in;*/
}

.side-drawer ul {
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.side-drawer li {
  margin: 0.5rem 0;
  padding-bottom: 1rem;
}

.side-drawer a,
.side-drawer span {
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
}

.side-drawer a:hover,
.side-drawer span:hover {
  text-decoration: none;
}

.profile-edit {
  position: relative;
  padding-right: 3px;
}

#profile-edit-text a,
#profile-edit-text span {
  font-size: 14pt;
}

.navigation-user-editlink:hover {
  text-decoration: underline;
}

.side-drawer a:hover,
.side-drawer a:active,
.side-drawer span:hover,
.side-drawer span:active {
  color: #db9d0c;
  cursor: pointer;
}

.navigation-user {
  margin-top: 1.5rem;
  text-align: center;
}

.navigation-user-picture {
  padding-left: auto;
  padding-right: auto;
}

.navigation-user-picture-img,
#navigation-user-picture-img {
  border-radius: 50%;
  width: 140px;
  height: 140px;
}

.navbar-desktop-extended-logo {
  height: 26px;
  width: 24px;
  object-fit: none;
  text-align: center;
}

.navbar-desktop-extended-logo path {
  stroke: none;
}

.side-drawer a,
.side-drawer span,
.navigation-user-name {
  color: #ffffff;
  font-family: helveticaNeueLtThinCn;
  font-size: 22pt;
}

.navigation-user-name-txt {
  font-size: 24pt !important;
}

.side-drawer a:hover > svg,
.side-drawer a:active > svg,
.side-drawer a:hover > svg > path,
.side-drawer a:active > svg > path,
.side-drawer span:hover > svg,
.side-drawer span:active > svg,
.side-drawer span:hover > svg > path,
.side-drawer span:active > svg > path {
  stroke: none;
  fill: #db9d0c;
}

.navigation-links {
  bottom: 0;
  width: 100%;
  float: left;
  position: static !important;
  margin-top: 0%;
}

.navigation-container {
  display: flex;
  margin-top: auto;
}

.navigation-links-nav > ul > li {
  margin: 0.5rem 0;
  padding-bottom: 0rem;
}

.navigation-links-nav > ul {
  padding-inline-start: 0px;
  padding-left: 2.05rem;
  margin: 0;
  padding-bottom: 0;
  width: 350px;
}

.navigation-links-logo {
  margin-bottom: 1rem;
  padding-left: auto;
  padding-right: auto;
}

.navigation-links-button {
  padding-left: 2rem;
}

.navigation-logo-container {
  height: 121.5px;
  text-align: center;
}

.navigation-logo {
  padding-top: 2rem;
}

.navigation-user-name {
  margin-top: 0.5rem;
  font-size: 22pt;
}

@media (min-width: 684px) {
  .side-drawer {
    background: #474f5a;
  }
}

/** Modal for password change */
.password-edit-row {
  margin: 1px 20px;
  justify-content: flex-end;
}

.password-edit-txt {
  float: right;
  max-width: 230px;
  min-width: 230px;
}

.password-change-button {
  background-color: transparent;
  border: 0;
  padding: 0;
  margin-left: 10px;
  border-radius: 0;
  transition: none;
  color: rgb(219, 157, 12);
  border-bottom: 1px solid rgb(219, 157, 12);
  font-family: Helvetica, sans-serif;
  font-weight: lighter;
  float: right;
}

.password-change-button:hover {
  border-bottom: 1px solid rgb(15, 25, 41, 74%);
  background-color: transparent;
  color: rgb(15, 25, 41, 74%);
}
