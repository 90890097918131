/*--------------------------------------------*
remove browser autofill
*--------------------------------------------*/
@keyframes autofill {
  to {
    color: #666;
    background: transparent;
  }
}

@font-face {
  font-family: helveticaNeueLtThinCn;
  src: url(/fonts/helveticaneueltthincn.ttf);
}

input:-webkit-autofill {
  -webkit-animation-name: autofill;
  animation-name: autofill;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

textarea:focus,
input:focus,
button:focus {
  outline: none !important;
  box-shadow: none !important;
}

/*--------------------------------------------*
global style for all pages
*--------------------------------------------*/
.app {
  width: 100%;
  height: 100%;
}

.title {
  position: static;
  text-align: center;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
}

.collapsed {
  padding-left: 90px;
}

.expanded {
  padding-left: 350px;
}

.custom-input-group {
  margin-bottom: 20px;
}

.form-control {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #0f1929;
  border-radius: 0px;
  margin-bottom: 20px;
  transition: none;
}

.form-control:active {
  background-color: transparent;
  border-bottom: 1px solid rgb(219, 157, 12);
}

.form-control:focus {
  background-color: transparent;
  border-bottom: 1px solid rgb(219, 157, 12);
}

.input-group-text {
  background-color: transparent;
  border: 0px;
}

.custom-input-group-addon {
  border: 0;
  border-bottom: 2px solid rgba(15, 25, 41, 0.1);
}

.input-icon {
  width: 20px;
  height: 20px;
  margin-top: auto;
  margin-bottom: auto;
}

.search-field {
  display: flex;
  justify-content: center;
  max-width: 300px;
  min-width: 300px;
}

.loading-icon {
  position: relative;
  padding-top: 60%;
  margin-left: 50%;
  transform: translate(-50%, -50%);
  justify-content: center;
}

.loading-icon-container {
  display: flex;
  position: relative;
  justify-content: center;
  align-content: center;
}

.page-wrap-loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.35;
  z-index: 25000;
}

.modal-loading-screen {
  position: absolute;
  top: 50%;
  left: 50%;
  justify-content: center;
  transform: translate(-50%, -50%);
  z-index: 30000;
}

.text-editor-label {
  min-width: 150px;
}

.no-data-found {
  opacity: 0.6;
}

@media only screen and (max-width: 1200px), screen and (max-height: 740px) {
  .collapsed {
    padding-left: 0px;
  }
}

.hide {
  display: none !important;
}
