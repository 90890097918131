/*-------------------------------------------*
    basic style toolbar desktop version
    TODO: delete this and replace it with 
    the css for mobile and desktop in the 
    final version.
*-------------------------------------------*/

.toolbar {
  height: 100%;
  background: #474f5a;
  width: 90px;
  padding-left: 1rem;
  align-items: center;
  padding-right: 1rem;
}

.toolbar_navigation {
  width: 100%;
  height: 100%;
  align-items: center;
  bottom: 0;
  padding-bottom: 1rem;
  position: absolute;
}

.toolbar_navigation-items {
  position: absolute;
  bottom: 0;
  text-align: center;
  padding-bottom: 1.8rem;
}

.toolbar_logo {
  height: 98px;
}

.toolbar-icon {
  padding-top: 1rem;
}

.toolbar_logo a {
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
}

.icon:hover {
  color: #db9d0c;
}

.toolbar_navigation-items a {
  color: white;
  text-decoration: none;
}
.toolbar_navigation-items a:hover {
  color: #db9d0c;
  text-decoration: none;
}

.toolbar_navigation-items ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.toolbar_navigation-items li {
  margin: 0.5rem 0;
  padding-bottom: 1rem;
}

.toolbar_navigation-items a:hover,
.toolbar_navigation-items a:active {
  color: #db9d0c;
}

.spacer {
  flex: 1;
}

.toolbar_navigation-items a:hover > svg,
.toolbar_navigation-items a:active > svg,
.toolbar_navigation-items a:hover > svg > path,
.toolbar_navigation-items a:active > svg > path {
  stroke: none;
  fill: #db9d0c;
}

.toolbar-burger-menu {
  margin-left: -11px;
}
