/*MemberPage*/

.row {
  min-width: 290px;
}

.member-logo {
  min-height: 66px;
  min-width: 57px;
  max-height: 66px;
  max-width: 57px;
  margin-top: -100px;
}

.member-page-container {
  display: flex;
  position: relative;
  justify-content: center;
  align-content: center;
}

/*SearchFieldMember*/

.filter-icon {
  width: 24px;
  height: 24px;
  margin-top: -14px;
  margin-left: -8px;
  justify-content: center;
}

.filter-button {
  border: 1px solid rgb(15, 25, 41, 0%);
  background-color: rgb(15, 25, 41, 0%);
  width: 30px;
  height: 30px;
  margin-top: -14px;
  transition-duration: 0.4s;
}

.dropdown-toggle::after {
  margin-left: 2px !important;
}

.filter-button:hover {
  background-color: rgb(15, 25, 41, 0%);
}

.filter-button svg {
  stroke: #656c77;
}

.filter-button:hover > svg {
  stroke: rgb(219, 157, 12);
}

.filter-button:active {
  box-shadow: none;
}

.filter-button:focus {
  box-shadow: none;
}

.search-container {
  width: 100%;
  height: 65px;
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0px;
  z-index: 1000;
  transition: top 0.6s;
}

.search-container-full {
  background-color: rgb(230, 231, 233);
  width: 100%;
  height: 65px;
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0px;
  z-index: 1000;
  transition: top 0.6s;
}

.search-container--hidden {
  top: -100px;
}

.search-field-member {
  position: absolute;
  display: inline-block;
  top: 20px;
}

.search-field-member.row {
  margin-left: 0;
  margin-right: 0;
}

.search-form {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 800px) {
  .search-container {
    min-width: 300px;
    max-width: 300px;
    margin-left: calc(-150px + 50%);
  }
}

@media only screen and (max-width: 400px) {
  .search-container {
    min-width: 220px;
    max-width: 220px;
    margin-left: calc(-90px + 50%);
  }
}

@media only screen and (max-width: 370px) {
  .search-container {
    min-width: 190px;
    max-width: 190px;
    margin-left: calc(-75px + 50%);
  }

  .search-field-member {
    min-width: 260px;
    max-width: 260px;
  }
}

.search-icon {
  width: 20px;
  height: 20px;
  margin-top: -6px;
}

.search-input {
  background: 'none';
  border: 'none';
  font-family: Helvetica, sans-serif;
  font-weight: 0;
  font-size: 22px;
  color: #656c77;
  margin-top: -50px;
  margin-left: 5px;
  width: 76%;
}

.tester-Search {
  justify-content: center;
}

hr {
  height: 1px;
  border: 0;
  border-top: 1px solid #656c77;
  margin-top: -17px;
  padding: 0;
  margin-top: 0px;
  width: 100%;
}

.search-field {
  display: flex;
  justify-content: center;
}

.dropdown-menu.show {
  position: absolute;
  left: -95px;
}

/*MemberCard*/

* {
  box-sizing: border-box;
}
.member-card-row {
  display: block;
}
.member-card-col {
  justify-content: center;
}
.member-cards-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}
.member-cards-row::after {
  content: '';
}

.member-card {
  display: flex;
  justify-content: center;
  border: 0px solid white;
  min-width: 244px;
  max-width: 244px;
  min-height: 161px;
  max-height: 161px;
  border-radius: 0px;
  margin: 9px;
}

.overflow {
  height: auto;
  overflow: auto;
  overflow-wrap: break-word;
  padding: 0px;
}

.cardbody {
  border: none;
}

.card-image {
  border-radius: 50%;
  width: 100px;
  min-width: 70px;
  max-width: 70px;
  min-height: 70px;
  max-height: 70px;
  margin-left: -7px;
  margin-top: 0px;
}

.card-name {
  text-align: left;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-family: Helvetica;
  font-size: 14pt;
  margin-left: 75px;
  margin-top: -30px;
}

.card-name a {
  color: #000000;
}

.card-name a:hover {
  text-decoration: none;
}

.mail-icon {
  min-width: 21px;
  max-width: 21px;
  min-height: 18px;
  max-height: 18px;
  margin-top: 11px;
  margin-left: -7px;
}

.card-text-mail {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-family: Helvetica;
  font-size: 11.5pt;
  margin-top: 21px;
  margin-left: 25px;
}

.phone-icon {
  min-width: 18px;
  max-width: 18px;
  min-height: 18px;
  max-height: 18px;
  margin-top: 7px;
  margin-left: -7px;
}

a[href^='mailto:'] {
  color: black;
  text-decoration: none;
}

.card-text-phone {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-family: Helvetica;
  font-size: 11.5pt;
  margin-top: 48px;
  margin-left: 25px;
}

a[href^='tel:'] {
  color: black;
  text-decoration: none;
}

.profile-link-member {
  margin-left: 38px;
  margin-top: -12px;
}

.profile-text-member {
  color: rgb(219, 157, 12);
  font-size: 11pt;
  margin-left: 116px;
}

.profile-text-member:hover {
  color: rgb(15, 25, 41, 74%);
  text-decoration: none;
  font-size: 11pt;
  margin-left: 116px;
}

.profile-text-member svg {
  fill: rgb(219, 157, 12);
}

.profile-text-member:hover > svg {
  background-color: transparent;
  opacity: 0.5 !important;
}

.profile-arrow-member {
  height: 25px;
  width: 24px;
  margin-top: -0.4rem;
  margin-left: 2.5rem;
}

.drop-down-filter {
  margin-left: 25px;
}

.filter-cities {
  margin-left: 5px;
}

@media only screen and (max-width: 768px) and (min-width: 0px) {
  .dropdown-toggle::after {
    margin-left: 2px !important;
  }
}

@media only screen and (max-width: 480px) {
  .member-card {
    min-width: 274px;
    max-width: 274px;
    min-height: 170px;
    max-height: 170px;
  }

  .profile-arrow-member {
    margin-top: -2px;
    margin-left: 4.5em;
  }
}
