/*--------------------------------------------*
basic style profile page
*--------------------------------------------*/
* {
  box-sizing: border-box;
}

/*component bodies*/
.hidden {
  display: none;
}

/*Buttons*/
.button-save-edit {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid rgb(219, 157, 12);
  color: rgb(219, 157, 12);
  padding: 0;
  border-radius: 0;
  transition: none;
  height: 32px;
  margin-bottom: 10px;
  font-size: 140%;
  float: left;
}

.button-save-edit:active,
.button-save-edit:hover {
  background-color: transparent;
  opacity: 0.5;
}

.picture-button {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid rgb(219, 157, 12);
  line-height: 2.3;
  color: rgb(219, 157, 12);
  padding: 0;
  border-radius: 0;
  transition: none;
  height: 32px;
  margin-bottom: 10px;
  font-size: 110%;
  float: left;
  opacity: 1 !important;
}

.picture-button:hover,
.picture-button:active {
  background-color: transparent;
  opacity: 0.5 !important;
}

.main-title {
  font-size: 1.3rem;
  opacity: 0.8;
  margin-right: 3%;
}

.profile-page__container {
  max-width: 900px;
}

.value-label {
  float: right;
  max-width: 250px;
  min-width: 250px;
  margin: 0;
  padding-left: 0;
}

@media only screen and (max-width: 1200px), screen and (max-height: 740px) {
  .value-label {
    float: right;
    max-width: 180px;
    min-width: 180px;
    margin: 0;
    padding-left: 0;
  }
}

.space {
  margin-bottom: 8%;
}

.space-top {
  margin-top: 6%;
}
/*--------------------------------------------*
additional style VIEW profile page
*--------------------------------------------*/

/*main-info style*/
.business-info,
.private-info {
  padding-left: 0px;
  padding-top: 7%;
  border-top: solid 1px rgba(15, 25, 41, 0.7);
}
.private-info {
  vertical-align: top;
}

.overflow,
label.information {
  height: auto;
  overflow: auto;
  overflow-wrap: break-word;
  padding: 0px;
}

/*profile-pic style*/
.profile-image,
#profile-image {
  border-radius: 50%;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
}
label.information {
  padding-bottom: 3%;
}
.information,
label {
  font-size: 12pt;
  opacity: 0.6;
  color: #0f1929;
}

.icon-button {
  background-color: transparent;
  border: none;
  padding-right: 16px;
  padding-left: 16px;
  margin-top: 14px;
}

.icon-button:active,
.icon-button:focus,
.icon-button:hover {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.btn-secondary:active,
:hover .icon-button:hover {
  background-color: transparent;
  opacity: 0.5;
}
.offerings {
  margin-bottom: 2%; /*label + label.information*/

  text-align: left;
}
#offerings-label {
  padding-top: 1rem;
}

/*basic-info style*/
.salutation-label,
.name-label {
  margin-right: 5px;
  margin-bottom: 0px;
  opacity: 0.8;
}
.salutation-label {
  font-size: 1rem;
  opacity: 0.8;
}

.salutation-label,
.name-label {
  margin-right: 5px;
  margin-bottom: 0px;
  opacity: 0.8;
}

.name-label {
  font-size: 1.8rem;
  opacity: 0.8;
}
.salutation-label {
  font-size: 0.8rem;
  opacity: 0.8;
}
.profile-pic__image,
.profile-pic__icons {
  display: flex;
  justify-content: center;
}

.profile-pic__icons {
  margin-bottom: 6%;
}

button:focus {
  outline: none;
}

.profile-pic__image img {
  width: 180px;
  height: 180px;
}

.button:disabled {
  background-color: transparent;
}

.btn-secondary.disabled,
.btn-secondary:disabled,
.btn-secondary.disabled:hover {
  background-color: transparent;
  opacity: 0.3;
}

/*--------------------------------------------*
additional style EDIT profile page
*--------------------------------------------*/

.form-check-input {
  margin-top: 0.4rem;
}

.basic-info {
  margin-top: 193px;
}

/*text-field style*/
.form-control {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #0f1929;
  opacity: 0.8;
  padding: 0;
  border-radius: 0;
  transition: none;
  height: 25px;
  margin-bottom: 10px;
  position: relative;
  width: 250px;
  margin: 0;
  padding: 0;
}

@media only screen and (max-width: 1200px), screen and (max-height: 740px) {
  .form-control {
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid #0f1929;
    opacity: 0.8;
    padding: 0;
    border-radius: 0;
    transition: none;
    height: 25px;
    margin-bottom: 10px;
    position: relative;
    width: 180px;
    margin: 0;
    padding: 0;
  }
}

.form-control:active {
  background-color: transparent;
  border-bottom: 1px solid rgb(219, 157, 12);
}
.form-control:focus {
  background-color: transparent;
  border-bottom: 1px solid rgb(219, 157, 12);
}
.input-group-text {
  background-color: transparent;
  border: 0;
  transition: none;
  margin-bottom: 10px;
  height: 25px;
  position: relative;
  bottom: 2%;
  padding: 0;
}
.input-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0%;
}
.input-field-radio {
  justify-content: left;
  float: left;
  width: 250px;
}

#text-area-offers {
  box-sizing: border-box;
  resize: vertical;
  margin-bottom: 3%;
}

/*profilePic*/
.profile-image-edit {
  border-radius: 50%;
  margin-bottom: 0%;
}
.container-profilepic {
  margin-top: 100px;
}

/*radio-button style*/

.radio-label {
  padding-left: 5px;
  padding-right: 10px;
}

#radio-invoice {
  margin-top: 0;
  padding-left: 0%;
}

.required-field {
  color: rgb(219, 157, 12);
  display: inline;
  position: relative;
  bottom: 5px;
}

.invoice-spacing {
  margin-top: 1.65%;
}

.invoice-spacing-edit {
  margin-top: 2.5%;
}

@media only screen and (min-width: 769px) and (max-width: 1200px) {
  .basic-info {
    margin-top: 195px;
  }
  .button-save-edit {
    position: relative;
    bottom: 51px;
    z-index: 1000;
  }

  .input-field-radio {
    justify-content: left;
    float: left;
    width: 180px;
  }
}
@media only screen and (min-width: 0px) and (max-width: 768px) {
  .basic-info {
    margin-top: 0px;
  }
  .button-save-edit {
    position: relative;
    bottom: 51px;
    z-index: 1000;
  }

  .input-field-radio {
    justify-content: left;
    float: left;
    width: 178px;
  }
}

@media (width: 768px) {
  .basic-info {
    margin-top: 195px;
  }
}
