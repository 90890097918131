.editor {
  word-wrap: break-word;
  word-break: break-all;
  padding: 1% 0 2% 0;
}

.remainingCharactersRichtText {
  font-size: 10pt;
  padding-top: 5px;
  opacity: 0.6;
}

.header-one {
  font-size: 10pt;
}

.editor h1 {
  font-size: 18pt;
}

.editor h2 {
  font-size: 15pt;
}

.editor h3 {
  font-size: 13pt;
}

.editor a {
  color: rgb(219, 157, 12);
}

.custom-admin-link-input {
  vertical-align: text-bottom;
  height: 34px;
  max-width: 250px;
  padding: 0px 5px;
  border: none;
  background: #f0f0f0;
}

.draftJsToolbar__button__qi1gf {
  width: auto;
}
