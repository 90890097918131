/*-------------------------------------------*
    basic style app nav bar
*-------------------------------------------*/

.app-nav-bar {
  z-index: 2000;
  float: left;
  height: 100%;
  padding-left: 0px;
  padding-bottom: 0px;
  padding-top: 0px;
  position: fixed;
  top: 0;
  left: 0;
}

.sticky {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 81px;
  background-color: rgb(230, 231, 233);
  font-size: 20px;
}

.navbar {
  z-index: 1000;
  position: fixed;
  position: -webkit-fixed;
  top: 0;
  right: 0;
  width: 70px;
  height: 65px;
  background-color: rgb(230, 231, 233);
  transition: top 0.6s;
}

.navbar--hidden {
  /*top: -100px;*/
}

.bm-burger-button {
  top: 20px !important;
  left: 20px !important;
}

.bm-burger-button.visible {
  position: -webkit-absolute;
  position: absolute;
  bottom: 50px;
}

@media only screen and (max-width: 1200px), screen and (max-height: 740px) {
  .app-nav-bar {
    float: right;
    left: unset;
    right: 0;
  }
}
