.login-container {
  display: table;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding-bottom: 80px;
}

.login-middle {
  display: table-cell;
  vertical-align: middle;
}

.login-inner {
  margin-left: auto;
  margin-right: auto;
  max-width: 220px;
}

.input-container {
  position: relative;
}

.input-icon {
  top: 5px;
  vertical-align: middle;
  left: 5px;
  margin-top: 0px;
  margin-left: -30px;
  position: relative;
  top: 25px;
}

.icon-input {
  padding-left: 130px;
  margin-top: 15px;
}

.login-button {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgb(219, 157, 12);
  margin: 0px;
  padding: 0px;
  float: right;
  font-size: 20px;
  color: rgb(219, 157, 12) !important;
  font-family: Helvetica, sans-serif;
  transition: none;
  border-radius: 0px;
  margin-top: 20px;
  clear: right;
}

.login-button:hover,
.login-button:active {
  background-color: transparent;
  opacity: 0.5;
}

.login-button-additional:hover,
.login-button-additional:active {
  outline: none;
  text-decoration: none;
  background-color: transparent;
  opacity: 0.5;
}

.login-button-additional {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgba(77, 68, 96, 0.5);
  position: relative;
  float: left;
  margin: 0px;
  padding: 0px;
  font-size: 12px;
  color: rgba(77, 68, 96, 0.5) !important;
  font-family: Helvetica, sans-serif;
  transition: none;
  border-radius: 0px;
  clear: left;
  margin-top: 5%;
  display: inline-block;
}

.additional-login-box {
  position: relative;
  top: 6rem;
  margin: auto;
  width: 70%;
  padding: 10px 10px 10px 20px;
}

#login-member {
  margin-left: 14%;
}

.btn-secondary:hover,
.btn-secondary:active {
  background-color: transparent !important;
  border-color: inherit;
}

.login-logo {
  opacity: 0.5;
  width: 120px;
  display: block;
  margin: auto;
  margin-bottom: 40px;
}

#emailInp,
#passwordInp {
  width: 220px;
}
