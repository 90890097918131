/*--------------------------------------------*
basic style admin page
*--------------------------------------------*/
.adminTable th {
  border-bottom: 1px solid black;
}

.table td.icon-row {
  padding-right: 0;
}

.create-button {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid rgb(219, 157, 12);
  line-height: 2.3;
  color: rgb(219, 157, 12);
  margin-left: 0.7rem;
  padding: 0;
  border-radius: 0;
  transition: none;
  height: 32px;
  font-size: 110%;
  opacity: 1 !important;
}

.create-button:hover,
.create-button:active,
.admin-button:hover,
.admin-button:active {
  background-color: transparent;
  opacity: 0.5 !important;
}

.admin-button {
  background-color: transparent;
  border: 0;
  padding: 0;
  margin-left: 10px;
  border-radius: 0;
  transition: none;
  color: rgb(219, 157, 12);
  border-bottom: 1px solid rgb(219, 157, 12);
  font-family: Helvetica, sans-serif;
  font-weight: lighter;
  float: right;
}

a.admin-link:hover > svg > path {
  fill: rgb(15, 25, 41);
}

svg {
  padding-right: 5px;
}

.form-control-admin {
  border-color: rgb(15, 25, 41, 10%);
  display: block;
  float: right;
  margin-top: 0.6rem;
}

.admin-link {
  display: inline-block;
  padding-right: 8px;
}

.admin-link-edit {
  height: 30px;
}

.admin-link-delete {
  height: 30px;
}

.admin-link-role {
  height: 34px;
}

.admin-link-small {
  font-size: 13px;
}

.admin-link-large {
  font-size: 140%;
  margin-bottom: 14px;
}

.admin-link-right {
  float: right;
}

.form-control-admin {
  float: right;
}

.city-name-label {
  margin-left: 15px;
}

.top-area {
  margin-top: 1rem;
}

div .create-button {
  background-color: transparent;
}

.admin-form-control.form-control {
  width: 90%;
  margin-top: 1%;
  margin-left: 5px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: transparent;
  border-color: rgb(15, 25, 41, 10%);
  border-bottom-color: transparent;
}
.nav-tabs .nav-link:hover {
  border-color: rgb(15, 25, 41, 10%);
  border-bottom-color: transparent;
}

.admin-cursor:hover > svg > path {
  cursor: pointer;
  fill: rgb(15, 25, 41, 74%);
}

.checkbox-container {
  margin-left: 5px;
}

.dropdown-toggle::after {
  margin-left: 8px;
  width: 11px;
  height: auto;
}

.tooltip-inner {
  text-align: left;
  background-color: rgb(15, 25, 41, 100%);
}

.pre-mail {
  margin: 0;
  font-size: 12pt;
  font-family: inherit;
}

@media only screen and (min-width: 0px) and (max-width: 768px) {
  select#circle.form-control {
    float: right;
    width: 85%;
  }

  .form-control-admin {
    width: 130px;
  }

  td {
    font-size: 11pt;
  }

  input#title {
    float: right;
  }

  .admin-form-control.form-control {
    float: right;
    width: 85%;
  }

  .checkbox-container {
    width: 85%;
    float: right;
  }

  label.filter-cities {
    font-size: 10pt;
  }

  .dropdown-toggle::after {
    margin-left: 40px;
    width: 11px;
    height: auto;
    margin-top: 10px;
  }

  .filter-button-edit {
    padding-left: 25px !important;
    margin-left: 0 !important;
    position: relative;
    bottom: 62px !important;
    right: 0 !important;
  }

  .event-edit-txt {
    max-width: 200px !important;
    min-width: 200px !important;
  }

  .event-image {
    float: right;
    margin-bottom: 2%;
    width: 85% !important;
  }

  .picture-button-create {
    font-size: 12pt !important;
    right: 0 !important;
    bottom: 10px !important;
  }

  .event-edit-image {
    max-width: 200px !important;
    min-width: 200px !important;
  }
}

/** Events admin*/
.event-edit-row {
  margin: 1px 20px;
  justify-content: flex-end;
}
.event-edit-txt {
  float: right;
  max-width: 280px;
  min-width: 280px;
}

.event-edit-picture-input {
  float: right;
  position: relative;
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid rgb(219, 157, 12);
  line-height: 2.3;
  color: rgb(219, 157, 12);
  padding: 0;
  border-radius: 0;
  transition: none;
  height: 32px;
  margin-bottom: 10px;
  font-size: 12pt;
  float: left;
  opacity: 1 !important;
}

.event-edit-image {
  position: relative;
  top: 5px;
  float: right;
  max-width: 280px;
  min-width: 280px;
}

.past-events {
  float: right;
}

.past-event-txt {
  font-size: 94%;
  margin-top: -20px;
}

.admin-form-control.description-area {
  height: 150px;
}

.filter-button-edit {
  padding-left: 60px;
  position: relative;
  bottom: 48px;
  right: 40px;
}

.event-edit-txt-btn {
  float: right;
  max-width: 280px;
  min-width: 280px;
  height: 1px;
}

.btn-group {
  height: 0;
}

.picture-button-create {
  float: left;
  position: relative;
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid rgb(219, 157, 12);
  line-height: 2.3;
  color: rgb(219, 157, 12);
  padding: 0;
  border-radius: 0;
  transition: none;
  height: 32px;
  margin-bottom: 10px;
  font-size: 12pt;
  opacity: 1 !important;
}

div.form-control {
  border-bottom: none;
}

div.admin-create-box .pic-create:active,
div.admin-create-box .pic-create:focus {
  border-bottom: 0px !important;
}

.picture-button-create:hover,
.picture-button-create:active,
.event-edit-picture-input:hover,
.event-edit-picture-input:active {
  background-color: transparent;
  opacity: 0.5 !important;
}

.event-image {
  position: relative;
  top: 7px;
  margin-bottom: 5px;
  margin-left: 5px;
}
.admin-form-control-create-news {
  width: 99%;
  margin-top: 1%;
  margin-left: 5px;
}
