/*-------------------------------------------*
    basic style toggle button
*-------------------------------------------*/

button.toggle-button {
  background: transparent;
  border: none;
  padding: 0;
  margin-top: 22px;
  margin-left: 22px;
}

.toggle-button-arrow {
  margin-left: 270px;
  justify-content: space-around;
  background: transparent;
  border: none;
  padding: 0;
  margin-top: 22px;
}

button.toggle-button-arrow:hover > svg.svg-svg > path,
button.toggle-button:hover > svg.svg-svg > path {
  fill: #db9d0c !important;
}

.toggle-button_line {
  width: 30px;
  height: 2px;
  background: white;
}
